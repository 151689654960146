import React, { useState, useEffect } from "react";
import { useAuth } from "../../App";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js"; // hashing phone number
import "../../assets/RegistrationForm.css"; // styles
import AlertBox from "../AlertBox/AlertBox";

const countryCodes = [
    { code: "+1", country: "United States" },
    { code: "+91", country: "India" },
    { code: "+55", country: "Brazil" },
    // Can add more country codes as needed.
];

// TODO:: we could manage alert messages as a lookup object with a default
const DEFAULT_ALERT_ERROR = "We're very sorry but we were unable to register you for donation. Please try again later."

const CLIENT_READY_POLL_INTERVAL = 5000;

const RegistrationForm = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const consentFormUrl = process.env.REACT_APP_CONSENT_FORM_URL;
    const { setIsValidDonor } = useAuth();
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isPhoneInputDisabled, setIsPhoneInputDisabled] = useState(false); // State to disable input after submit
    const [countryCode, setCountryCode] = useState(countryCodes[0].code);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    const [validationMessage, setValidationMessage] = useState(""); // State for error message
    const [alert, setAlert] = useState(null);

    // Helper function to strip formatting and letters
    const stripFormatting = (input) => {
        const stripped = input.replace(/\./g, "").replace(/[^a-zA-Z0-9]/g, ""); // Remove periods, dashes, special symbols, etc.
        const lengthBefore = stripped.length;
        const lettersStripped = stripped.replace(/[a-zA-Z]/g, ""); // Remove letters
        return { lettersStripped, lengthBefore };
    };

    // Function to validate and format the phone number (adds country code and @c.us)
    const validatePhoneNumber = (phoneNumber) => {
        const { lettersStripped, lengthBefore } = stripFormatting(phoneNumber);
        const lengthAfter = lettersStripped.length;

        if (lengthAfter === lengthBefore) {
            // Check if the stripped phone number contains periods
            if (phoneNumber.includes(".")) {
                return ""; // Invalid if it contains periods
            }

            // Validate based on country code
            let formattedPhoneNumber = "";
            if (countryCode === "+55") {
                // Brazil: If country code is +55, expect 9 digits after stripping formatting
                if (lettersStripped.length === 9) {
                    formattedPhoneNumber = "55" + lettersStripped ; // Add country code
                } else {
                    return ""; // Invalid length for Brazil
                }
            } else {
                // Default case for other countries: Expect 10 digits
                if (lettersStripped.length === 10) {
                    formattedPhoneNumber = countryCode + lettersStripped 
                } else {
                    return ""; // Invalid length for other countries
                }
            }

            formattedPhoneNumber = formattedPhoneNumber.replace("+",""); // Remove the "+" sign
            return formattedPhoneNumber; // Return the properly formatted phone number
            
        }

        return ""; // Return empty string if letters were found
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validatedPhoneNumber = validatePhoneNumber(phoneNumber);

        if (validatedPhoneNumber) {
            setIsButtonDisabled(true);
            setIsPhoneInputDisabled(true); 
            setAlert({level: "info", message: "Thank you for registering with us. We will verify your number and QR code will appear here for you to scan ..."})
            setValidationMessage(""); // Clear any previous validation message

            const phoneNumberHash = CryptoJS.SHA256(validatedPhoneNumber).toString(
                CryptoJS.enc.Hex
            );

            try {
                const targetUrl = new URL("/api/register", baseUrl);
                const response = await axios.post(targetUrl, {
                    phoneNumberHash: phoneNumberHash,
                });
                const { qrCodeImageUrl } = response.data;
                setImageUrl(qrCodeImageUrl);
            } catch (error) {
                setAlert({level: "warning", message: DEFAULT_ALERT_ERROR})
                console.error("Registration failed:", error);

                // Check if the error is 400 status code
                if (error.response && error.response.status === 400) {
                    setAlert({level: "warning", message: "You are already registered with us - donation is allowed only once. "
                        + "If you feel this is an error please contact tbd@georgetown.edu."});
                } else {
                    setAlert({level: "warning", message: DEFAULT_ALERT_ERROR});
                }

                // Re-enable the submit button in case of error
                setIsButtonDisabled(false);
            }
        } else {
            setValidationMessage(
                countryCode === "+55"
                    ? "Invalid phone number: must contain exactly 9 digits for Brazil."
                    : "Invalid phone number: must contain exactly 10 digits."
            );
            setIsButtonDisabled(false);
        }
    };

    // Poll backend until client is ready, then redirect
    useEffect(() => {
        let interval;

        if (imageUrl) {
            interval = setInterval(async () => {
                try {
                    const targetUrl = new URL("/api/client-ready", baseUrl);
                    const response = await axios.get(targetUrl);
                    if (response.data.ready) {
                        clearInterval(interval);
                        setIsValidDonor(true);
                        navigate("/chat-selections");
                    }
                } catch (error) {
                    console.error("Failed to check client ready status:", error);
                }
            }, CLIENT_READY_POLL_INTERVAL); // Poll client-ready endpoint according to default interval
        }

        return () => clearInterval(interval);
    }, [baseUrl, imageUrl, navigate, setIsValidDonor]);

    // Show/Hide Application Info
    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    // Helper function to open consent form in new tab
    const openConsentForm = () => {
    // opens in new tab
        window.open(consentFormUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <div className="appContainer">
            <header className="header">
                <h1 className="title">WhatsApp Monitor</h1>
            </header>
            <div className="content">
                <h1>Registration: WhatsApp Link Explorer</h1>
                {!imageUrl && (
                    <div className="instructions-content">
                        <h2>Instructions</h2>
                        <p style={{ paddingRight: "75px" }}>
                            <strong>Step 1:</strong> Please enter your WhatsApp number
                        </p>
                        <p style={{ paddingLeft: "128px" }}>
                            <strong>Step 2:</strong> Please hit the Register button to
                            navigate to the QR code screen.
                        </p>
                    </div>
                )}
                <div className="infoButtonContainer">
                    {!imageUrl && (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label>
                                    <strong>Country Code: </strong>
                                </label>
                                <select
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    style={{ marginRight: "10px" }}
                                >
                                    {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {country.country} ({country.code})
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <label>
                                    <strong>WhatsApp Number: </strong>
                                </label>
                                <input
                                    type="tel"
                                    placeholder="Enter phone number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                    disabled={isPhoneInputDisabled} // Disable input after submit
                                />
                            </div>
                            {validationMessage && ( // Display error message
                                <div style={{ color: "red", marginTop: "10px" }}>
                                    {validationMessage}
                                </div>
                            )}
                            <button
                                className={isButtonDisabled ? "disabledButton" : "button"}
                                disabled={isButtonDisabled}
                                type="submit"
                            >
                                Submit
                            </button>
                            {alert && <AlertBox message={alert.message} level={alert.level} onClose={() => setAlert(null)} />}
                        </form>
                    )}
                    {imageUrl && (
                        <div className="content">
                            <p>
                                <strong>Step 3:</strong> Scan the QR code below to link your
                                account:
                            </p>
                            <ul className="outer-bullet">
                                <li className="inner-bullet-50">
                                    Open WhatsApp on your phone and go to Settings
                                </li>
                                <li className="inner-bullet-50">
                                    Go to Linked Devices and tap on Link Device
                                </li>
                                <li className="inner-bullet-50">
                                    Use the scanner on the displayed QR code to register
                                </li>
                                <li className="inner-bullet-50">
                                    The device linking process will begin on your phone
                                </li>
                                <li className="inner-bullet-50">
                                    The application will wait for your device to be linked
                                </li>
                                <li className="inner-bullet-50">
                                    A list of your chats will be displayed
                                </li>
                            </ul>
                            <br/>
                            <img src={imageUrl} alt="QR Code" />
                        </div>
                    )}
                </div>
                <br/>
                <div className="infoToggle" onClick={toggleInfo}>
                    {showInfo ? "Hide Application Info" : "Show Application Info"}
                </div>
                {showInfo && (
                    <div className="collapsibleText">
                        <p>
                            <strong>
                                This application allows you to share data about your WhatsApp
                                profile and from your WhatsApp groups. This is how the
                                application works:
                            </strong>
                        </p>
                        <ul className="outer-bullet">
                            <li className="inner-bullet-200">
                                You register in the application and scan a QR code to log in
                                into the application.
                            </li>
                            <li className="inner-bullet-200">
                                You will decide which groups you want to donate the messages
                                from.
                            </li>
                            <li className="inner-bullet-200">
                                To protect the privacy of these groups, you will only be able to
                                donate data from groups with more than 6 participants.
                            </li>
                        </ul>
                        <p className="register-page-p">
                            <strong>Which data will be collected?</strong>
                        </p>
                        <ul className="outer-bullet">
                            <li className="inner-bullet-200">
                                Messages with links (URLs) on the groups you selected to donate.
                            </li>
                            <li className="inner-bullet-200">
                                User metadata on your WhatsApp (bios, your groups, groups you
                                are part of, when you joined WhatsApp, among others).
                            </li>
                            <li className="inner-bullet-200">
                                The name, description and size of the groups you are part of.
                            </li>
                            <li className="inner-bullet-200">
                                Participants of the groups you donated the data from.
                            </li>
                        </ul>
                        <p className="register-page-p">
                            <strong>All the data will be encrypted.</strong>
                        </p>
                        <p className="register-page-p">
                            We will encrypt all your personal information (name, phone number,
                            email), and for the other group participants. In addition, we
                            minimize the risk of collecting personal and potentially sensitive
                            information by restricting our data collection only to links. The
                            vast majority of these links contain public information available
                            on the web.
                        </p>
                        <p className="register-page-p">
                            <strong>We will NOT collect:</strong>
                        </p>
                        <p className="register-page-p">
                            Any content from groups you decide not to donate data, any content
                            from one-on-one conversations, any images, videos, gifs, and memes
                            from any conversation, any messages that do not contain links.
                        </p>
                    </div>
                )}
                <br></br>
                <div>
                    <a title="Opens in a new tab" onClick={openConsentForm}>
                        View consent form
                    </a>
                </div>
            </div>
            <footer className="footer-registration">
                <p>&copy; {new Date().getFullYear()} WhatsApp Monitor-MDI.</p>
            </footer>
        </div>
    );
};

export default RegistrationForm;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../assets/ChatSelectionsPage.css";
import "../../../src/App.css";
import AlertBox from "../AlertBox/AlertBox";


/** Constants */
const BASE_URL = process.env.REACT_APP_BASE_URL;

// TODO:: check this val, should either be slightly more or less than server timeout
const TIMEOUT = process.env.REACT_APP_LONG_RESPONSE_TIMEOUT * 1.1 || 175000;
// Set slighly longer than the request timeout
const CHAT_SELECTIONS_BAR_TIMEOUT = TIMEOUT * 1.1;


const ChatSelectionsPage = () => {
    const [isLoading, setIsLoading] = useState(true); // show progress bar while loading
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [allSelected, setAllSelected] = useState(true); // Tracks if all groups are selected for toggle
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [progress, setProgress] = useState(0);
    const [alert, setAlert] = useState(null);

    // Toggle Select/Unselect all button
    const toggleAllGroups = () => {
        if (allSelected) {
            setSelectedGroups([]); // Unselect all
        } else {
            const allGroupIds = groups.map((group) => group.id);
            setSelectedGroups(allGroupIds); // Select all
        }
        setAllSelected(!allSelected); // Toggle the selection state
    };

    // Warn user if they attempt to navigate away from page during loading
    // TODO:: apply this to all pages after register?
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isLoading) {
                event.preventDefault();
                event.returnValue = ""; // Required for some browsers
            }
        };

        // Add event listener
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isLoading]);

    // fetch group chats from server and display for selection
    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const targetUrl = new URL("/api/fetch-all-group-chats", BASE_URL);
                const response = await axios.get(targetUrl, {
                    timeout: TIMEOUT,
                });
                var group_chats = response.data.group_chats;
                setGroups(group_chats);
                // Set all group IDs as selected
                const allGroupIds = group_chats.map((group) => group.id);
                setSelectedGroups(allGroupIds);
            } catch (error) {
                console.error("Error fetching groups:", error);
                // TODO:: handle failed fetch-all-group-chats with retry?
                setAlert({level: "warning", message: "We're very sorry but we were unable to fetch your selected chat groups. Please try again later."})
            } finally {
                setIsLoading(false);
            }
        };

        fetchGroups(); // Fetch data when the component mounts
    }, []);

    // Animate progress bar
    useEffect(() => {
        if (isLoading) {
            const interval = setInterval(() => {
                setProgress((prev) => Math.min(prev + 1, 99));
            }, CHAT_SELECTIONS_BAR_TIMEOUT / 100); // 5-minute loading max time

            return () => clearInterval(interval);
        } else {
            setProgress(100); // Set to 100 when loading is complete
        }
    }, [isLoading]);

    const handleCheckboxChange = (groupId) => {
    // Check if groupId is already in selectedGroups
        const isSelected = selectedGroups.includes(groupId);
        if (isSelected) {
            // If groupId is already selected, filter it out
            setSelectedGroups(selectedGroups.filter((id) => id !== groupId));
        } else {
            // If groupId is not selected, add it to selectedGroups
            setSelectedGroups([...selectedGroups, groupId]);
        }
    };

    // navigation for successful donation
    const navigateToLinksPage = () => {
        navigate("/dashboard");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsButtonDisabled(true);
        // console.log('User Selected groups:', selectedGroups);
        try {
            const targetUrl = new URL("/api/set-selected-groups", BASE_URL);
            const response = await axios.post(targetUrl, { selectedGroups });
            if (response.status === 200) {
                navigateToLinksPage();
            }
        } catch (error) {
            console.error("Error processing selected groups:", error);
            setAlert({level: "warning", message: "We're very sorry but we were unable to process your selected chats. Please try again later."})
            setIsButtonDisabled(false);
        }
    };

    return (
        <div className="app-container">
            {/* Header */}
            <header className="header">
                <h1 className="title">WhatsApp Monitor</h1>
            </header>

            <div className="ChatSelectionsPage">
                {isLoading ? (
                    <div className="loading-container">
                        <p>Loading, this may take up to 5 minutes...</p>
                        <div className="progress-bar-container">
                            <div
                                className="progress-bar"
                                style={{ width: `${progress}%` }}
                            ></div>
                        </div>
                    </div>
                ) : (
                    <>
                        <h1>Choose Groups to Donate From</h1>
                        <button
                            type="button"
                            onClick={toggleAllGroups}
                            className="toggle-button"
                        >
                            {allSelected ? "Unselect All" : "Select All"}
                        </button>
                        <form className="form-container">
                            <div className="checkbox-container">
                                {groups.map((group) => (
                                    <div key={group.id._serialized} className="checkbox-item">
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={group.id}
                                                checked={selectedGroups.includes(group.id)}
                                                onChange={() => handleCheckboxChange(group.id)}
                                            />
                                            {group.name || group.subject}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </form>
                        <button
                            className={`donate-button ${
                                isButtonDisabled || selectedGroups.length === 0
                                    ? "disabled"
                                    : ""
                            }`}
                            onClick={handleSubmit}
                            disabled={isButtonDisabled || selectedGroups.length === 0}
                        >
                            Donate Metadata
                        </button>
                        {alert && <AlertBox header="Error:" message={alert.message} level={alert.level} onClose={() => setAlert(null)} />}
                    </>
                )}
            </div>
            {/* Footer */}
            <footer className="footer">
                <p className="footer-text">
                    © {new Date().getFullYear()} WhatsApp Monitor. All rights reserved.
                </p>
            </footer>
        </div>
    );
};

export default ChatSelectionsPage;
import React from "react";
import PropTypes from "prop-types";
import "./AlertBox.css";
import {
    FaCircleExclamation,
    FaCircleCheck,
    FaCircleXmark,
    FaCircleInfo,
    FaSquareXmark,
} from "react-icons/fa6";

const AlertBox = ({ level, message, header, onClose }) => {
    const getIcon = (level) => {
        switch (level) {
        case "warning":
            return <FaCircleExclamation size="2em" />;
        case "error":
            return <FaCircleXmark size="2em" />;
        case "success":
            return <FaCircleCheck size="2em" />;
        default:
            return <FaCircleInfo size="2em" />;
        }
    };

    return (
        <div className={`alert ${level}`}>
            <div className="icon_cell">{getIcon(level)}</div>
            <div className="body_cell">
                {header && <b>{header}&nbsp;</b>}{message}
            </div>
            <div className="note__close" onClick={onClose}>
                <FaSquareXmark size="1.4em" />
            </div>
        </div>
    );
};

// Prop validation for better maintainability
AlertBox.propTypes = {
    level: PropTypes.string,
    message: PropTypes.string,
    header: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default AlertBox;

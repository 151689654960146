// frontend app.js
import "./App.css";
import React, { createContext, useContext, useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import RegistrationForm from "./components/pages/Register.js";
import ChatSelectionsPage from "./components/pages/ChatSelectionsPage.js";
import DashboardPage from "./components/pages/DashboardPage.js";

// Create a Context for Authentication
const AuthContext = createContext();

const App = () => {
    const [isValidDonor, setIsValidDonor] = useState(false);

    return (
        <AuthContext.Provider value={{ isValidDonor, setIsValidDonor }}>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<RegistrationForm />} />
                        <Route
                            path="/chat-selections"
                            element={
                                isValidDonor ? <ChatSelectionsPage /> : <Navigate to="/" />
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={isValidDonor ? <DashboardPage /> : <Navigate to="/" />}
                        />
                        {/* Catch-all for non-defined routes */}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </Router>
        </AuthContext.Provider>
    );
};

// Custom hook to use auth context
export const useAuth = () => useContext(AuthContext);

export default App;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/Dashboard.css";
import "../../../src/App.css";

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    // Top 10 Group Chat Names
    const [topGroupChats, setTopGroupChats] = useState([]);
    // Top 10 URLs
    const [topUrls, setTopUrls] = useState([]);
    // Total Count of URLs
    const [totalUrlCount, setTotalUrlCount] = useState(0);
    // Total Count of Group Chats
    const [totalGroupChatCount, setTotalGroupChatCount] = useState(0);
    const [error, setError] = useState("");
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch all data in parallel
                const [groupChatsRes, urlsRes, urlCountRes, groupChatCountRes] =
          await Promise.all([
              axios.get(new URL("/api/fetch-dashboard-group-chats", baseURL)),
              axios.get(new URL("/api/fetch-dashboard-donor-urls", baseURL)),
              axios.get(new URL("/api/count-unique-donated-urls", baseURL)),
              axios.get(new URL("/api/count-unique-group-chats", baseURL)),
          ]);

                // Set state for each data set
                setTopGroupChats(groupChatsRes.data);
                setTopUrls(urlsRes.data);
                setTotalUrlCount(urlCountRes.data);
                setTotalGroupChatCount(groupChatCountRes.data);
            } catch (err) {
                setError(err);
                console.error("Dashboard error fetching data:", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [baseURL]);

    if (isLoading) {
        return <p>Loading, please wait...</p>;
    }

    if (error) {
        console.warn("ERROR CAUGHT: ", error);
        return <p>Error: {error.message || "An unknown error occurred"}</p>;
    }

    const styles = {
        appContainer: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
        },
        header: {
            backgroundColor: "#4CAF50",
            padding: "10px 20px",
            borderBottom: "1px solid #ddd",
            textAlign: "center",
        },
        dashboard: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            overflowY: "auto",
            height: "78vh",
        },
        summaryContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
        },
        summaryCard: {
            padding: "15px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
        },
        ul: {
            listStyleType: "disc",
            paddingLeft: "20px",
            margin: 0,
            maxWidth: "100%",
        },
        link: {
            color: "#007bff",
            textDecoration: "none",
        },
        linkHover: {
            textDecoration: "underline",
        },
        thankYouText: {
            fontSize: "20px",
            margin: "10px 0",
            color: "#333",
            textAlign: "center",
        },
        title: {
            margin: 0,
            fontSize: "24px",
            color: "#333",
        },
        footer: {
            backgroundColor: "#4CAF50",
            padding: "10px 20px",
            borderTop: "1px solid #ddd",
            textAlign: "center",
        },
        text: {
            margin: 0,
            fontSize: "14px",
            color: "#333",
        },
    };

    return (
        <div style={styles.appContainer}>
            {/* Header */}
            <header style={styles.header}>
                <h1 style={styles.title}>WhatsApp Monitor</h1>
            </header>
            {/* Thank You Message */}
            <h2 style={styles.thankYouText}>
                Thank you Survey Participant! Summary of data donated:
            </h2>
            <div style={styles.dashboard}>
                <div className="dashboard">
                    {error && <p>{error}</p>}
                    <div style={styles.summaryContainer}>
                        <div style={styles.summaryCard}>
                            <h2>Total Count of Unique URLs</h2>
                            <p className="green-text">{totalUrlCount}</p>
                        </div>

                        <div style={styles.summaryCard}>
                            <h2>Total Count of Group Chats</h2>
                            <p className="green-text">{totalGroupChatCount}</p>
                        </div>

                        <div style={styles.summaryCard}>
                            <h2>Top 10 Group Chat Names</h2>
                            <ul style={styles.ul}>
                                {topGroupChats.map((chat) => (
                                    <li
                                        style={{
                                            listStylePosition: "inside",
                                            margin: "0",
                                            paddingLeft: "200px",
                                            textAlign: "left",
                                        }}
                                        key={chat.subject}
                                        className="green-text"
                                    >
                                        {chat.subject} - {chat.donated_message_nbr}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div style={styles.summaryCard}>
                            <h2>Top 10 URLs Shared</h2>
                            <ul style={styles.ul}>
                                {topUrls.map((urlObj) => (
                                    <li
                                        style={{
                                            listStylePosition: "inside",
                                            margin: "0",
                                            paddingLeft: "200px",
                                            textAlign: "left",
                                            whiteSpace: "nowrap", // Prevents the text from wrapping to the next line
                                            overflow: "hidden", // Hides overflowed content
                                            textOverflow: "ellipsis", //Adds (...) to truncate the longer URLs.
                                        }}
                                        key={urlObj.url}
                                        className="green-text"
                                    >
                                        {urlObj.url}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            {/* Footer */}
            <footer style={styles.footer}>
                <p style={styles.text}>
                    © {new Date().getFullYear()} WhatsApp Monitor.
                </p>
            </footer>
        </div>
    );
};

export default Dashboard;
